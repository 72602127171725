import axios from 'axios';
import api from './AxiosConfig';


// Function to get the public IP of the user
const getPublicIP = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Error fetching public IP:', error);
    return '';
  }
};


export const getFrames = async (text, modelId) => {
  try {
    // First, await the IP address before making the POST request
    const ipAddress = await getPublicIP(); // Make sure to await the Promise

    const response = await api.post(`/v1/film-wizard/frames`, {
      "ip_address": ipAddress, // Now ipAddress is a string, not a Promise
      "script": text,
      "model_id": modelId
    });
    return response.data;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};


export const getFrame = async (videoId, frameId, caption, modelId) => {
  try {
    const response = await api.put(`/v1/film-wizard/video/${videoId}/frame`, {
      "frame_index": frameId, // Now ipAddress is a string, not a Promise
      "new_caption": caption,
      "model_id": modelId
    });
    return response.data;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};