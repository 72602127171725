import api from './AxiosConfig';

export const getScenes = async (videoId, modelId) => {
  try {
    const response = await api.post(`/v1/film-wizard/video/${videoId}/scenes`, {
      "model_id": modelId
    });
    return response.data;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};

export const getScene = async (videoId, sceneId, modelId) => {
  try {
    const response = await api.put(`/v1/film-wizard/video/${videoId}/scene`, {
      "scene_index": sceneId,
      "model_id": modelId
    });
    return response.data;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};

export const generateFinalClip = async (videoId) => {
  try {
    const response = await api.post(`/v1/film-wizard/video/${videoId}`);
    return response.data;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};