import React, { useState } from 'react';
import { FaSync } from 'react-icons/fa';
import { getFrame } from '../../services/ImageService';
import './FrameViewer.css';

const FrameViewer = ({ frames, onConfirm, onBack, videoId, modelId  }) => {
    const [frameData, setFrameData] = useState(Object.values(frames));
    const [loadingFrames, setLoadingFrames] = useState(new Array(frames.length).fill(false)); // Array to track loading state


    const handleCaptionChange = (index, value) => {
        const newFrameData = [...frameData];
        newFrameData[index].caption = value;
        setFrameData(newFrameData);
    };

    const handleRefresh = async (index) => {
        const newLoadingFrames = [...loadingFrames];
        newLoadingFrames[index] = true; // Set loading state to true
        setLoadingFrames(newLoadingFrames);

        const frame = frameData[index];
        const newFrame = await getFrame(videoId, frame.id, frame.caption, modelId);
        if (newFrame && newFrame.frame.url) {
            const newFrameData = [...frameData];
            newFrameData[index].url = newFrame.frame.url;
            setFrameData(newFrameData);
        }

        newLoadingFrames[index] = false; // Set loading state to false
        setLoadingFrames(newLoadingFrames);
    };

    return (
        <div className="frame-viewer">
            <div className="frame-grid">
                {frameData.map((frame, index) => (
                    <div key={frame.id} className="frame-item">
                        <img src={frame.url} alt={`Frame ${index + 1}`} />
                        <div className="frame-caption-container">
                            <textarea
                                className="frame-caption"
                                value={frame.caption}
                                onChange={(e) => handleCaptionChange(index, e.target.value)}
                                rows={3} // Set default rows to 3
                            />
                            <button onClick={() => handleRefresh(index)} className="refresh-btn" disabled={loadingFrames[index]}>
                                <FaSync className={loadingFrames[index] ? 'spinning' : ''}/> {/* Add spinning class */}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="button-container">
                <button className="back-btn" onClick={onBack}>Another Aesthetic</button>
                <button className="confirm-btn" onClick={onConfirm}>Confirm Frames</button>
            </div>
        </div>
    );
};

export default FrameViewer;