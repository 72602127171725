import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthHandler from './components/auth/AuthHandler';
import LandingPage from './components/LandingPage';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth-handler" element={<AuthHandler />} />
        <Route path="/"  element={<LandingPage />} />
        {/* Define other routes here */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
