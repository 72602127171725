import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // if you're using react-router

const AuthHandler = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Extract JWT from URL
        const urlParams = new URLSearchParams(window.location.search);
        const jwt = urlParams.get('jwt');

        if (jwt) {
            // Store the JWT token
            localStorage.setItem('jwt', jwt);

            // Clear the URL parameters
          
            if (typeof window !== 'undefined') {
                window.history.replaceState({}, document.title, window.location.pathname);
            }
         

            // Redirect to the landing page or dashboard
            navigate('/landing');
        }
    }, [navigate]);

    // Optionally, display a loading indicator or a "Logging in..." message
    return <div>Logging in...</div>;
};

export default AuthHandler;
