import React, { useState, useRef, useEffect } from 'react';
import AestheticsSelector from './wizard/AestheticsSelector';
import FrameViewer from './wizard/FrameViewer';
import VideoViewer from './wizard/VideoViewer';
import FinalVideoViewer from './wizard/FinalVideoViewer';
import AnimatedCircularProgressBar from "../@/components/magicui/animated-circular-progress-bar";
import { getFrames } from '../services/ImageService';
import { generateFinalClip, getScenes } from '../services/VideoService';
import { getModels } from '../services/ModelService';

const Wizard = ({ text }) => {
    const [frames, setFrames] = useState([]);
    const [scenes, setScenes] = useState([]);
    const [aesthetics, setAesthetics] = useState([]);
    const [showAestheticsSelector, setShowAestheticsSelector] = useState(false);
    const [showFrameViewer, setShowFrameViewer] = useState(false);
    const [showVideoViewer, setShowVideoViewer] = useState(false);
    const [showFinalVideoViewer, setShowFinalVideoViewer] = useState(false);
    const [isIntermediateLoading, setIsIntermediateLoading] = useState(false);
    const [lastGeneratedVideoUrl, setLastGeneratedVideoUrl] = useState(''); // State to hold the last generated video URL
    const [progressValue, setProgressValue] = useState(0);
    const [videoId, setVideoId] = useState('');
    const [modelId, setModelId] = useState('');
    const wizardRef = useRef(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchModels = async () => {
            setIsIntermediateLoading(true);
            wizardRef.current.scrollIntoView({ behavior: 'smooth' });
            setProgressValue(0); // Reset progress value when starting to fetch models
            try {
                const models = await getModels(text); // Fetch model data based on the script text
                setAesthetics(models); // Update the aesthetics state with the fetched models
                setIsIntermediateLoading(false);
                setShowAestheticsSelector(true); // Show the AestheticsSelector
                console.log('Models:', models);
            } catch (err) {
                console.error('Error fetching models:', err);
                setError('Failed to fetch models. Please try again.');
            }
        };

        if (text.trim()) {
            fetchModels();
        }
    }, [text, setError]);

    const handleAestheticSelect = async (aesthetic) => {
        setShowAestheticsSelector(false);
        setProgressValue(0); // Reset progress value to 0
        setIsIntermediateLoading(true);
        setModelId(aesthetic.id);
        setError('');
        try {
            const response = await getFrames(text, aesthetic.id);
            setFrames(response.video.frames);
            setVideoId(response.video.id);
            setProgressValue(100);
            setTimeout(() => {
                setIsIntermediateLoading(false);
                setShowFrameViewer(true);
                if (wizardRef.current) {
                    wizardRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 1000); // Adjust the delay as necessary
            console.log('Submit:', text);
        } catch (err) {
            console.error('Error submitting video request:', err);
            setError('Failed to submit. Please try again.');
            setIsIntermediateLoading(false);
        }
    };

    const handleConfirmFrames = async () => {
        setShowFrameViewer(false);
        setProgressValue(0); // Reset progress value to 0
        setIsIntermediateLoading(true);
        try {
            const response = await getScenes(videoId, modelId);
            setScenes(response.video.scenes);
            setProgressValue(100);
            setTimeout(() => {
                setIsIntermediateLoading(false);
                setShowVideoViewer(true);
                if (wizardRef.current) {
                    wizardRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 1000); // Adjust the delay as necessary
        } catch (err) {
            console.error('Error fetching animated frames:', err);
            setError('Failed to fetch animated frames. Please try again.');
            setIsIntermediateLoading(false);
        }
    };

    const handleGenerateVideo = async () => {
        setShowVideoViewer(false);
        setProgressValue(0); // Reset progress value to 0
        setIsIntermediateLoading(true);
        try {
            const response = await generateFinalClip(videoId);
            setLastGeneratedVideoUrl(response.video_url);
            setProgressValue(100);
            setTimeout(() => {
                setIsIntermediateLoading(false);
                setShowFinalVideoViewer(true);
                if (wizardRef.current) {
                    wizardRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 1000); // Adjust the delay as necessary
        } catch (err) {
            console.error('Error generating video:', err);
            setError('Failed to generate video. Please try again.');
            setIsIntermediateLoading(false);
        }
    };

    const handleDownloadVideo = () => {
        fetch(lastGeneratedVideoUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = "final-clip.mp4";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    useEffect(() => {
        if (isIntermediateLoading) {
            const interval = setInterval(() => {
                setProgressValue(prev => (prev < 100 ? prev + 10 : prev));
            }, 10000);
            return () => clearInterval(interval);
        }
    }, [isIntermediateLoading]);

    return (
        <>
            <section ref={wizardRef} className="wizard-container mb-16">
                {showAestheticsSelector && (
                    <AestheticsSelector
                        aesthetics={aesthetics}
                        onSelect={handleAestheticSelect}
                    />
                )}

                {isIntermediateLoading && (
                    <div className="flex justify-center items-center">
                        <AnimatedCircularProgressBar
                            max={100}
                            min={0}
                            value={progressValue}
                            gaugePrimaryColor="rgb(79 70 229)"
                            gaugeSecondaryColor="rgba(0, 0, 0, 0.1)"
                        />
                    </div>
                )}

                {showFrameViewer && (
                    <FrameViewer
                        frames={frames}
                        onConfirm={handleConfirmFrames}
                        onBack={() => {
                            setShowFrameViewer(false);
                            setShowAestheticsSelector(true);
                        }}
                        videoId={videoId}
                        modelId={modelId}
                    />
                )}

                {showVideoViewer && (
                    <VideoViewer
                        scenes={scenes}
                        onBack={() => {
                            setShowVideoViewer(false);
                            setShowFrameViewer(true);
                        }}
                        onGenerateVideo={handleGenerateVideo}
                        videoId={videoId}
                        modelId={modelId}
                    />
                )}

                {showFinalVideoViewer && (
                    <FinalVideoViewer
                        videoUrl={lastGeneratedVideoUrl}
                        onBack={() => {
                            setShowFinalVideoViewer(false);
                            setShowVideoViewer(true);
                        }}
                        onDownload={handleDownloadVideo}
                    />
                )}


            </section>

            {/* Error message section */}
            {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
        </>
    );
};

export default Wizard;