import React, { useState, useEffect } from 'react';
import { FaSync } from 'react-icons/fa'; // For the refresh icon
import { getScene } from '../../services/VideoService'; // Adjust if you have a specific service for fetching videos
import './VideoViewer.css';

const VideoViewer = ({ scenes, onBack, onGenerateVideo, videoId, modelId }) => {
    const [sceneData, setSceneData] = useState(Object.values(scenes));
    const [loadingScenes, setLoadingScenes] = useState(new Array(scenes.length).fill(false)); // Array to track loading state


    useEffect(() => {
        setSceneData(Object.values(scenes));
    }, [scenes]);

    const handleRefresh = async (index) => {
        const newLoadingScenes = [...loadingScenes];
        newLoadingScenes[index] = true; // Set loading state to true
        setLoadingScenes(newLoadingScenes);

        const scene = sceneData[index];
        const newScene = await getScene(videoId, scene.id, modelId);
        if (newScene && newScene.scene.url) {
            console.log('Refreshing video at index:', index, 'with URL:', newScene.scene.url);
            const newSceneData = [...sceneData];
            newSceneData[index] = {
                ...newSceneData[index],
                url: newScene.scene.url,
                key: Date.now() // Adding a unique key for each update
            };
            setSceneData(newSceneData);
        } else {
            console.error('Failed to refresh video');
        }

        newLoadingScenes[index] = false; // Set loading state to false
        setLoadingScenes(newLoadingScenes);
    };

    return (
        <div className="video-viewer">
            <div className="video-grid">
                {sceneData.map((scene, index) => (
                    <div key={scene.id} className="video-item">
                        <video key={scene.key || scene.id} width="100%" controls>
                            <source src={scene.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>

                        <div className="frame-caption-container">
                            <textarea
                                className="frame-caption"
                                value="Hit the refresh button to get a new animation based on this image."
                                readOnly
                                rows={3} // Set default rows to 3
                            />
                            <button onClick={() => handleRefresh(index)} className="refresh-btn" disabled={loadingScenes[index]}>
                                <FaSync className={loadingScenes[index] ? 'spinning' : ''}/> {/* Add spinning class */}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="button-container">
                <button onClick={onBack} className="video-viewer-buttons">Modify Frames</button>
                <button onClick={onGenerateVideo} className="video-viewer-buttons">Generate Video</button>
            </div>
        </div>
    );
};

export default VideoViewer;