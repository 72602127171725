import React from 'react';

function LoginModal({ isOpen, onClose, onLogin }) {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed', // Position the modal above everything else
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)', // Semi-transparent background
      display: 'flex',
      alignItems: 'center', // Center the modal vertically
      justifyContent: 'center', // Center the modal horizontally
      zIndex: 1000, // Ensure the modal is above other content
    }}>
      <div style={{
        padding: '20px',
        background: '#fff',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <p>You need to log in to continue.</p>
        <button onClick={onLogin} style={{ margin: '10px' }}>Login</button>
        <button onClick={onClose} style={{ margin: '10px' }}>Close</button>
      </div>
    </div>
  );
}

export default LoginModal;
